// Importing this overrides the other styles on the site
// @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .select-blue {
    @apply appearance-none focus:appearance-none hover:appearance-none active:appearance-none;
    @apply bg-pewterBlueSelectArrow focus:bg-pewterBlueSelectArrow hover:bg-pewterBlueSelectArrow;
    @apply bg-dark-blue focus:bg-dark-blue hover:bg-dark-blue active:bg-dark-blue;
    @apply text-white focus:text-white hover:text-white active:text-white;
    @apply bg-[center_right_9px] focus:bg-[center_right_9px] hover:bg-[center_right_9px] active:bg-[center_right_9px];
    @apply bg-no-repeat;
    @apply border-pewter-blue focus:border-pewter-blue hover:border-pewter-blue active:border-pewter-blue;
    @apply pl-1 focus:pl-1 hover:pl-1;
  }

  .page-header {
    @apply flex gap-6 items-center justify-between;
    @apply px-6;
    @apply h-[80px];
  }

  .page-header__logo {
    @apply block;
  }

  .page-header__logo__image {
    @apply h-[55px];
  }

  .change-plan-modal {
    @apply hidden;
    @apply bg-white;
  }

  .change-plan-modal.show {
    @apply block fixed;
    @apply top-0 left-0 w-screen h-screen;
  }

  .change-plan-modal__close,
  .change-plan-modal__close:focus,
  .change-plan-modal__close:hover,
  .change-plan-modal__close:active {
    @apply absolute top-0 right-0;
    @apply px-4 py-2;
    @apply text-dark-blue;

    // override foundation button styles
    @apply bg-transparent;
    @apply transition-none;
    @apply normal-case;
    @apply h-auto;
  }
}
